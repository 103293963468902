import React from "react"
import { Main, SpanBox, Button } from "./style"
import { Shadow } from "../../SearchPage/Header/style"

const Header = () => {
  return (
    <Main>
      <Shadow />
      <SpanBox>
        <h1>UPS</h1>
        <h2>NIC TU NIE MA</h2>
        <h3>WRÓĆ NA STRONĘ GŁÓWNĄ</h3>
        <Button to="/"> &lt; POWRÓT</Button>
      </SpanBox>
    </Main>
  )
}

export default Header

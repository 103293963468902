import styled from "styled-components"
import errorBackground from "../../../images/error-background.jpg"
import { Link } from "gatsby-plugin-react-intl"

export const SpanBox = styled.div`
  margin-bottom: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 4;
  gap: 13px;

  @media (max-width: 550px) {
    gap: 25px;
  }
`

export const Main = styled.div`
  position: relative;
  background: url(${errorBackground}) top center no-repeat fixed;
  background-size: 100% auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8.5rem;
  gap: 22px;
  aspect-ratio: 16/9;

  @media (max-width: 1149px) {
    height: 650px;
    width: 100%;
    background-size: auto 100%;
  }

  h1 {
    font-size: 8.4rem;
    font-weight: 700;
    line-height: 8rem;
    letter-spacing: 0.21em;
    text-align: center;
    color: #fdfdfd;
  }

  h3 {
    color: #fdfdfd;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 2.1rem;
    letter-spacing: 0.15em;
    text-align: center;
    margin-top: 30px;
  }

  h2 {
    font-size: 2rem;
    font-weight: 600;
    line-height: 3rem;
    letter-spacing: 0.24em;
    text-align: center;
    color: #fdfdfd;
    padding-bottom: 30px;
    width: 100%;
    border-bottom: 1.5px solid #fdfdfd;

    @media (max-width: 500px) {
      width: 80%;
      text-align: center;
    }
  }
`

export const Button = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  width: 235px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2.1rem;
  letter-spacing: 0.15em;
  text-align: center;
  color: #fdfdfd;
  border: 1.5px solid #fdfdfd;
`

import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/ErrorPage/Header"

const Error = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Header />
  </Layout>
)

export default Error
